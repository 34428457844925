import { memo } from "react";

import MuiDialog from "@mui/material/Dialog";
import { styled, Theme } from "@mui/material/styles";

import { InternalClasses } from "../helpers";

export type BaseDialogClasses =
  | "globalPadding"
  | "root"
  | "paper"
  | "paperFullWidth"
  | "container"
  | "header"
  | "iconCloseContainer"
  | "iconWrapper"
  | "subtitle"
  | "icon"
  | "defaultColor"
  | "content"
  | "footer";

const ICON_SIZE = 24;

const PREFIX = "ZeffyDialog";

export const zeffyDialogClasses: InternalClasses<typeof PREFIX, BaseDialogClasses> = {
  globalPadding: `${PREFIX}-globalPadding`,
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  container: `${PREFIX}-container`,
  paperFullWidth: `${PREFIX}-paperFullWidth`,
  header: `${PREFIX}-header`,
  iconCloseContainer: `${PREFIX}-iconCloseContainer`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  subtitle: `${PREFIX}-subtitle`,
  icon: `${PREFIX}-icon`,
  defaultColor: `${PREFIX}-defaultColor`,
  content: `${PREFIX}-content`,
  footer: `${PREFIX}-footer`,
};

export const StyledDialog = memo(
  styled(MuiDialog)(({ theme }: { theme: Theme }) => ({
    [`& .${zeffyDialogClasses.globalPadding}`]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    [`& .${zeffyDialogClasses.header}`]: {
      paddingTop: theme.spacing(3),
    },

    [`& .${zeffyDialogClasses.iconCloseContainer}`]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    [`& .${zeffyDialogClasses.iconWrapper}`]: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },

    [`& .${zeffyDialogClasses.subtitle}`]: {
      color: theme.palette.neutralVariant[50],
    },

    [`& .${zeffyDialogClasses.icon}`]: {
      height: ICON_SIZE,
      width: ICON_SIZE,
    },

    [`& .${zeffyDialogClasses.defaultColor}`]: {
      color: theme.palette.neutralVariant[10],
      fill: theme.palette.neutralVariant[10],
    },

    [`& .${zeffyDialogClasses.content}`]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    [`& .${zeffyDialogClasses.footer}`]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }))
);
