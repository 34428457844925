import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";

import { ConsentModeContext } from "../../contexts/ConsentModeContext";
import { useIsIframe } from "../../hooks/useIsIframe";
import { useTranslate } from "../../hooks/useTranslate";
import { ConsentModeFormFields } from "../../types/consent";
import Image from "../Image/Image";
import { UserConsentForm } from "../UserConsentForm/UserConsentForm";

import { useStyles } from "./useStyles";

import { Dialog, DialogProps } from "@/design-system/Dialog";
import { getDefaultConsentMode, parseConsentModeFormFields } from "@/helpers/consent";

interface UserConsentProps {
  skipModal?: boolean;
}

export const UserConsent: FC<UserConsentProps> = ({ skipModal = false }) => {
  const { t } = useTranslate();
  const classes = useStyles();
  const theme = useTheme();
  const { hasConsentMode, handleSetConsentMode, isUserInQuebec, isUserInQuebecLoading } =
    useContext(ConsentModeContext);
  const isIframe = useIsIframe();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);

  useEffect(() => {
    // While isUserInQuebecLoading, we don't know if the user is in Quebec or not
    if (!isUserInQuebecLoading && !hasConsentMode) {
      if (isUserInQuebec && !skipModal) {
        setIsModalOpen(true);
      }
      if (!isUserInQuebec) {
        handleSetConsentMode(getDefaultConsentMode("granted"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserInQuebecLoading]);

  const form = useForm<ConsentModeFormFields>({
    defaultValues: {
      analytical: true,
      functional: true,
      marketing: true,
    },
  });

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onShowForm = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  const handleSubmit = useCallback(
    (consentModeFormFields: ConsentModeFormFields) => {
      const parsedFormFields = parseConsentModeFormFields(consentModeFormFields);
      handleSetConsentMode(parsedFormFields);
      closeModal();
    },
    [closeModal, handleSetConsentMode]
  );

  const acceptAllCookies = useCallback(
    () => handleSubmit({ analytical: true, functional: true, marketing: true }),
    [handleSubmit]
  );

  const onSubmit = form.handleSubmit(async (consentModeFormFields: ConsentModeFormFields) => {
    handleSubmit(consentModeFormFields);
  });

  const dialogClasses: DialogProps["classes"] = useMemo(
    () => ({
      root: classes.dialogRoot,
      container: classes.dialogContainer,
      paper: classes.dialogPaper,
      paperFullWidth: classes.dialogPaperFullWidth,
    }),
    [classes.dialogContainer, classes.dialogPaper, classes.dialogPaperFullWidth, classes.dialogRoot]
  );

  // Manually overriding the inline styles for a custom design
  const dialogStyle: CSSProperties = useMemo(
    () => ({
      zIndex: theme.zIndex.consent,
    }),
    [theme.zIndex.consent]
  );

  // Do not render the modal if the user is in an iframe like Preview/Review form
  if (isIframe) {
    return null;
  }

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={isModalOpen}
      title={isFormVisible ? t("dashboard", "userConsent.cookieSettings") : undefined}
      actions={{
        main: { onClick: acceptAllCookies, children: t("dashboard", "userConsent.acceptAllCookies") },
        cancel: {
          children: isFormVisible
            ? t("dashboard", "userConsent.saveMyPreferences")
            : t("dashboard", "userConsent.cookieSettings"),
          onClick: isFormVisible ? onSubmit : onShowForm,
          variant: "text",
        },
      }}
      classes={dialogClasses}
      style={dialogStyle}
      onClose={closeModal}
      disableEscapeKeyDown
      hideBackdrop
      disablePortal
      disableAutoFocus
      hideIcon
    >
      <Grid container spacing={2} alignItems="center" data-test="user-consent-modal">
        {!isFormVisible && (
          <>
            <Grid item xs={12} className={classes.cookieContainer} data-test="user-consent-main">
              <Image alt="cookies" width={120} height={105} src="/images/cookies.png" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                {t("dashboard", "userConsent.weUseCookies")}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="body2" align={isFormVisible ? undefined : "center"}>
            {t("dashboard", "userConsent.description")}
          </Typography>
        </Grid>
        {isFormVisible && <UserConsentForm control={form.control} />}
      </Grid>
    </Dialog>
  );
};
