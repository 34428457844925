import { forwardRef } from "react";

import { Box, drawerClasses, Skeleton } from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import classnames from "classnames";

import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useMergeClasses } from "../useMergeClasses";

import { DrawerHeader } from "./DrawerHeader";
import { zeffyDrawerClasses, StyledDrawer } from "./styles";
import { DrawerProps } from "./types";

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      size = "medium",
      open,
      classes: externalClasses,
      children,
      title,
      isLoading,
      anchor = "right",
      skeleton,
      noDivider,
      noPadding,
      width,
      height,
      hasStickyHeader,
      hasStickyFooter,
      sx,
      slots,
      closePlacement,
      onClose,
      ...rest
    },
    ref
  ) => {
    const classes = useMergeClasses(zeffyDrawerClasses, externalClasses);

    const { isSmallScreen } = useMediaQuery();

    return (
      <StyledDrawer
        {...rest}
        anchor={anchor}
        open={open}
        ModalProps={{ BackdropProps: { style: { backgroundColor: alpha(grey[900], 0.3) } } }}
        classes={{
          root: classnames(classes.root),
          paper: classnames({
            [classes.small]: size === "small" && !isSmallScreen,
            [classes.medium]: size === "medium" && !isSmallScreen,
            [classes.fullWidth]: anchor === "bottom" || isSmallScreen,
            [classes.bottomContainer]: anchor === "bottom",
          }),
        }}
        sx={(theme) => ({
          [`& .${drawerClasses.paper}`]: {
            width,
            height,
            maxWidth: "100%",
            transitionProperty: "all",
          },
          ...(typeof sx === "function" ? sx(theme) : sx),
        })}
        onClose={onClose}
        ref={ref}
      >
        <Grid container className={classes.container} direction="column">
          {!slots?.header && (
            <DrawerHeader
              title={title}
              hasStickyHeader={hasStickyHeader}
              noDivider={noDivider}
              slots={slots}
              closePlacement={closePlacement}
              onClose={onClose}
              classes={externalClasses}
            />
          )}
          {slots?.header && (
            <Grid item className={classnames({ [classes.stickyHeader]: hasStickyHeader })}>
              {slots?.header}
            </Grid>
          )}

          <Grid
            item
            xs
            className={classes.contentContainer}
            sx={{
              "&.MuiGrid-item": {
                maxWidth: "100%",
              },
            }}
          >
            <div
              className={classnames(classes.content, {
                [classes.contentPadding]: !noPadding,
                [classes.contentMobilePadding]: anchor === "bottom",
              })}
            >
              {isLoading && (
                <>
                  {Boolean(skeleton) && skeleton}
                  {!skeleton && <Skeleton variant="rectangular" height="100%" width="100%" />}
                </>
              )}
              {!isLoading && children}
            </div>
          </Grid>
          {slots?.footer && (
            <Box className={classnames(classes.footer, { [classes.stickyFooter]: hasStickyFooter })}>
              {slots?.footer}
            </Box>
          )}
        </Grid>
      </StyledDrawer>
    );
  }
);

Drawer.displayName = "Drawer";
