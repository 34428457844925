import { useEffect } from "react";

import { isSafari } from "react-device-detect";

// https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
// Reloading the page if it is cached on Safari for the onboarding flow back button to work
export const useDisableSafariBackForwardCache = () => {
  useEffect(() => {
    if (isSafari && window) {
      window.onpageshow = (event) => {
        if (event.persisted) {
          window.location.reload();
        }
      };
    }
  }, []);
};
