import { memo, useCallback, useState } from "react";

import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";
import { Nullable } from "@simplyk/common";
import { useRouter } from "next/router";

import { isProduction } from "../../constants/env";
import { useCurrentUserContext } from "../../contexts/CurrentUserContext";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Icon } from "../design-system/Icon";

import { Check } from "@/icons/filled";
import { Copy } from "@/icons/outlined";

const PREFIX = "DevContextCopy";

const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
};
const StyledGrid = memo(
  styled(Grid)(({ theme }: { theme: Theme }) => ({
    [`&&.${classes.container}`]: {
      cursor: "pointer",
      height: size,
      width: size,
      backgroundColor: theme.palette.secondary.main,
      position: "fixed",
      top: -size / 2,
      right: -size / 2,
      opacity: 0.5,
      zIndex: 100000000,
      borderRadius: 5,
      transition: "top 0.2s ease, right 0.2s ease",

      "&:hover": {
        top: 0,
        right: 0,

        [`&& .${classes.icon}`]: {
          display: "block",
        },
      },
    },

    [`&& .${classes.icon}`]: {
      margin: 4,
      display: "none",
    },
  }))
);

interface DevContextCopyProps {
  formId: Nullable<string>;
}

const DevContextCopyContent = ({ formId }: DevContextCopyProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const { currentUser, organization } = useCurrentUserContext();
  const router = useRouter();
  const media = useMediaQuery();

  const copyLink = useCallback(() => {
    let timeout: Nullable<NodeJS.Timeout> = null;
    const payload = {
      organizationId: organization?.id,
      organizationName: organization?.name,
      userId: currentUser?.id,
      email: currentUser?.email,
      route: router.asPath,
      connectedAsAdministrator: currentUser?.connectedAsAdministrator,
      screen: Object.entries(media)
        .filter(([key, value]) => value && key !== "value")
        .map(([key]) => key),
      agents: process.browser ? window.navigator.appVersion : "",
      timestamp: new Date().getTime(),
      formId,
    };

    try {
      navigator.clipboard.writeText(
        "Dev context \n" +
          Object.entries(payload)
            .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
            .join("\n")
      );
      setIsCopied(true);
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 800);
    } catch {
      return;
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    currentUser?.connectedAsAdministrator,
    currentUser?.email,
    currentUser?.id,
    formId,
    media,
    organization?.id,
    organization?.name,
    router.asPath,
  ]);

  return (
    <StyledGrid container className={classes.container} onClick={copyLink}>
      <Icon size="small" className={classes.icon}>
        {isCopied ? <Check /> : <Copy />}
      </Icon>
    </StyledGrid>
  );
};

const size = 25;

export const DevContextCopy = (props: DevContextCopyProps) => {
  const { currentUser } = useCurrentUserContext();
  if (!isProduction) {
    return <DevContextCopyContent {...props} />;
  }
  if (currentUser?.connectedAsAdministrator) {
    return <DevContextCopyContent {...props} />;
  }
  return null;
};
