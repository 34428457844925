import { forwardRef, useCallback, useMemo, useState, MouseEvent } from "react";

import Box from "@mui/material/Box";
import MuiDialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useTranslate } from "../../../hooks/useTranslate";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { IconButton } from "../IconButton";
import { Tooltip } from "../Tooltip";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyDialogClasses, StyledDialog } from "./styles";
import { DialogProps } from "./types";

import { Error, Success as CheckCircle, Cross as Close, Info, Warning } from "@/icons/outlined";

export const DEFAULT_DIALOG_VIBE: DialogProps["vibe"] = "brand";
export const DEFAULT_DIALOG_MAXWIDTH: DialogProps["maxWidth"] = "sm";

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      actions,
      children,
      classes: externalClasses,
      icon,
      renderIcon: externalRenderIcon,
      title,
      vibe = DEFAULT_DIALOG_VIBE,
      dialogContentId,
      fullScreenOnMobile,
      hideCloseButton,
      hideIcon = false,
      maxWidth = DEFAULT_DIALOG_MAXWIDTH,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslate();
    const { isPhoneScreen } = useMediaQuery();
    const isFullScreenMobile = isPhoneScreen && fullScreenOnMobile;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const classes = useMergeClasses(zeffyDialogClasses, externalClasses);

    const vibeIcon = useMemo(() => {
      if (vibe === "brand") {
        return <Info />;
      }
      if (vibe === "danger") {
        return <Error />;
      }
      if (vibe === "warning") {
        return <Warning />;
      }
      if (vibe === "positive") {
        return <CheckCircle />;
      }
    }, [vibe]);

    const renderIcon = useMemo(() => {
      if (hideIcon) {
        return;
      }

      if (externalRenderIcon) {
        return externalRenderIcon();
      }

      return (
        <Box
          sx={(theme) => ({
            padding: 0.75,
            background: theme.palette.surface[vibe].quiet,
            display: "inline-flex",
            borderRadius: 1,
          })}
        >
          <Icon className={classes.icon} vibe={`text-${vibe}-quiet`} size="large">
            {icon || vibeIcon}
          </Icon>
        </Box>
      );
    }, [hideIcon, externalRenderIcon, classes.icon, vibe, icon, vibeIcon]);

    const handleClose = useCallback(() => {
      rest.onClose?.();
    }, [rest]);

    const handleSubmit = useCallback(
      async (event: MouseEvent<HTMLButtonElement>) => {
        setIsSubmitting(true);
        await actions?.main?.onClick?.(event);
        setIsSubmitting(false);
      },
      [actions?.main]
    );

    const displayCancelButton = actions?.cancel && !actions.cancel.hidden;
    const displayMainButton = actions?.main && !actions.main.hidden;

    const currentDialogClasses = useMemo(
      () => ({
        root: classes.root,
        paper: classes.paper,
        paperFullWidth: classes.paperFullWidth,
        container: classes.container,
      }),
      [classes.container, classes.paper, classes.paperFullWidth, classes.root]
    );

    const mainButton = useMemo(() => {
      if (!actions?.main || !displayMainButton) {
        return null;
      }
      const { tooltip, isLoading, ...buttonProps } = actions.main;
      const button = (
        <Button
          variant="filled"
          vibe={vibe}
          {...buttonProps}
          onClick={handleSubmit}
          isLoading={isSubmitting || isLoading}
          fullWidth
        >
          {actions?.main.children || t("dashboard", "common.confirm")}
        </Button>
      );
      if (tooltip) {
        return (
          <Tooltip title={tooltip.title} placement={tooltip.placement} arrow>
            <Grid item>{button}</Grid>
          </Tooltip>
        );
      }
      return <Grid item>{button}</Grid>;
    }, [actions?.main, displayMainButton, handleSubmit, isSubmitting, t, vibe]);

    const cancelButton = useMemo(() => {
      if (!actions?.cancel || !displayCancelButton) {
        return null;
      }
      const { tooltip, ...buttonProps } = actions.cancel;

      const button = (
        <Button variant="outlined" vibe="neutral" {...buttonProps} fullWidth>
          {actions?.cancel.children || t("dashboard", "common.cancel")}
        </Button>
      );

      if (tooltip) {
        return (
          <Tooltip title={tooltip.title} placement={tooltip.placement} arrow>
            <Grid item>{button}</Grid>
          </Tooltip>
        );
      }
      return <Grid item>{button}</Grid>;
    }, [actions?.cancel, displayCancelButton, t]);

    const additionalButtons = actions?.additional?.map((buttonProps) => (
      <>
        {!buttonProps.hidden && (
          <Grid item key={buttonProps.children?.toString()}>
            <Button {...buttonProps} fullWidth />
          </Grid>
        )}
      </>
    ));

    const renderTitle = (
      <Box>
        <Typography variant="h6" className={classes.defaultColor}>
          {title}
        </Typography>
      </Box>
    );

    const hasClose = rest.onClose && !hideCloseButton;

    return (
      <StyledDialog
        {...rest}
        classes={currentDialogClasses}
        ref={ref}
        fullScreen={isFullScreenMobile || rest.fullScreen}
        maxWidth={maxWidth}
      >
        {(title || !hideIcon || hasClose) && (
          <div className={classnames(classes.header, classes.globalPadding)}>
            {(!hideIcon || hasClose || (title && hideIcon)) && (
              <div className={classes.iconCloseContainer}>
                <div className={classes.iconWrapper}>
                  {!hideIcon && renderIcon}
                  {Boolean(title && hideIcon) && renderTitle}
                </div>
                {hasClose && (
                  <IconButton vibe="neutral" onClick={handleClose} size="small" data-test="close-dialog">
                    <Close />
                  </IconButton>
                )}
              </div>
            )}

            {title && !hideIcon && <Box marginTop={!hideIcon || hasClose ? 2 : 0}>{renderTitle}</Box>}
          </div>
        )}

        <MuiDialogContent className={classnames(classes.globalPadding, classes.content)} id={dialogContentId}>
          <Typography variant="body2">{children}</Typography>
        </MuiDialogContent>
        {(displayCancelButton || displayMainButton) && (
          <Grid
            container
            className={classnames(classes.footer, classes.globalPadding)}
            justifyContent={isPhoneScreen ? "flex-start" : "flex-end"}
            direction={isPhoneScreen ? "column" : "row"}
            spacing={1}
          >
            {isPhoneScreen ? (
              <>
                {mainButton}
                {cancelButton}
                {additionalButtons}
              </>
            ) : (
              <>
                {additionalButtons}
                {cancelButton}
                {mainButton}
              </>
            )}
          </Grid>
        )}
      </StyledDialog>
    );
  }
);

Dialog.displayName = "Dialog";
