import { useCallback } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Divider } from "../Divider/Divider";
import { IconButton } from "../IconButton";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyDrawerClasses } from "./styles";
import { DrawerHeaderProps } from "./types";

import { Cross as CloseIcon } from "@/icons/outlined";

export const DrawerHeader = ({
  title,
  hasStickyHeader,
  noDivider,
  slots,
  closePlacement,
  onClose,
  classes: externalClasses,
}: DrawerHeaderProps) => {
  const classes = useMergeClasses(zeffyDrawerClasses, externalClasses);
  const { isSmallScreen } = useMediaQuery();

  const handleClose = useCallback(() => {
    onClose?.({}, "closeButton");
  }, [onClose]);

  const closeButton = (
    <Grid item xs>
      {slots?.close ? (
        slots?.close
      ) : (
        <IconButton vibe="neutral" size="medium" onClick={handleClose} data-test="drawer-close-button">
          {slots?.closeIcon ?? <CloseIcon />}
        </IconButton>
      )}
    </Grid>
  );

  const currentClosePlacement = closePlacement ?? (isSmallScreen ? "right" : "left");

  return (
    <Grid item container className={classnames(classes.header, { [classes.stickyHeader]: hasStickyHeader })}>
      <Grid item xs={12}>
        <Grid container className={classes.headerTitle} alignItems="center" justifyContent="center">
          {currentClosePlacement === "left" ? (
            closeButton
          ) : (
            <Grid item xs>
              {slots?.headerAction}
            </Grid>
          )}
          {typeof title === "string" && (
            <Grid item className={classes.title}>
              <Typography variant="h6">{title}</Typography>
            </Grid>
          )}
          {typeof title !== "string" && title}
          <Grid item xs>
            <Grid container justifyContent="flex-end">
              <Grid item>{currentClosePlacement === "right" ? closeButton : slots?.headerAction}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!noDivider && (
        <Grid
          item
          xs={12}
          className={classnames(classes.headerDivider, { [classes.mobileHeaderDivider]: isSmallScreen })}
        >
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};
