import { useState, useEffect } from "react";

export const useIsIframe = () => {
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    setIsIframe(window.self !== window.top);
  }, []);

  return isIframe;
};
