import type { JSX } from "react";

import Grid, { GridProps } from "@mui/material/Grid";
import classnames from "classnames";

import { Button } from "../Button/Button";
import { Icon, IconProps } from "../Icon";
import { Typography } from "../Typography";

import { zeffySelectBarClasses, StyledGrid } from "./styles";

import { ChevronRight } from "@/icons/outlined";

export interface SelectBarAction {
  label: string;
  onClick: () => void;
  icon: JSX.Element;
}

export interface SelectBarProps {
  title: React.ReactNode | string;
  description: string;
  onClick?: () => void;
  icon?: JSX.Element;
  iconProps?: Partial<IconProps>;
  renderIcon?: JSX.Element;
  selected?: boolean;
  action?: SelectBarAction;
  hideArrow?: boolean;
  sx?: GridProps["sx"];
}

export const SelectBar = ({
  title,
  description,
  onClick,
  icon,
  selected,
  iconProps,
  hideArrow,
  renderIcon,
  action,
  sx,
}: SelectBarProps) => {
  return (
    <StyledGrid
      container
      className={classnames(zeffySelectBarClasses.container, {
        [zeffySelectBarClasses.containerSelected]: selected,
        [zeffySelectBarClasses.clickable]: !!onClick,
      })}
      onClick={onClick}
      sx={sx}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Grid container direction="column">
            {(renderIcon || icon) && (
              <Grid item>
                {renderIcon || (
                  <Icon size="large" {...iconProps}>
                    {icon}
                  </Icon>
                )}
              </Grid>
            )}
            <Grid item>{typeof title === "string" ? <Typography variant="subtitle1">{title}</Typography> : title}</Grid>
            <Grid item>
              <Typography variant="body2" vibe="text-neutral-quiet">
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {action ? (
            <Button
              variant="outlined"
              vibe="neutral"
              onClick={(e) => {
                e.stopPropagation();
                action.onClick();
              }}
              endIcon={action.icon}
            >
              {action.label}
            </Button>
          ) : (
            !hideArrow && (
              <Icon className={zeffySelectBarClasses.icon}>
                <ChevronRight />
              </Icon>
            )
          )}
        </Grid>
      </Grid>
    </StyledGrid>
  );
};
