import { FC, useMemo } from "react";

import Grid from "@mui/material/Grid";
import { Control } from "react-hook-form";

import { useTranslate } from "../../hooks/useTranslate";
import { ConsentModeFormFields } from "../../types/consent";

import { useStyles } from "./useStyles";

import { Checkbox, CheckboxProps } from "@/design-system/Checkbox";
import { Typography } from "@/design-system/Typography";

export interface UserConsentFormProps {
  control: Control<ConsentModeFormFields>;
}

export const UserConsentForm: FC<UserConsentFormProps> = ({ control }) => {
  const { t } = useTranslate();
  const classes = useStyles();

  const optionLabelClasses: CheckboxProps<ConsentModeFormFields>["optionLabelClasses"] = useMemo(
    () => ({
      label: classes.label,
    }),
    [classes.label]
  );

  return (
    <>
      <Grid item xs={12} data-test="user-consent-necessary">
        <Checkbox
          label={t("dashboard", "userConsent.necessary")}
          size="small"
          optionLabelClasses={optionLabelClasses}
          checked
          disabled
        />
        <Typography vibe="neutralVariant50" variant="caption">
          {t("dashboard", "userConsent.necessary.description")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          label={t("dashboard", "userConsent.functional")}
          size="small"
          optionLabelClasses={optionLabelClasses}
          name="functional"
          control={control}
        />
        <Typography vibe="neutralVariant50" variant="caption">
          {t("dashboard", "userConsent.functional.description")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          label={t("dashboard", "userConsent.marketing")}
          size="small"
          optionLabelClasses={optionLabelClasses}
          name="marketing"
          control={control}
        />
        <Typography vibe="neutralVariant50" variant="caption">
          {t("dashboard", "userConsent.marketing.description")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          label={t("dashboard", "userConsent.analytical")}
          size="small"
          optionLabelClasses={optionLabelClasses}
          name="analytical"
          control={control}
        />
        <Typography vibe="neutralVariant50" variant="caption">
          {t("dashboard", "userConsent.analytical.description")}
        </Typography>
      </Grid>
    </>
  );
};
