import { memo } from "react";

import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";

import { getTransitions } from "../css";
import { InternalClasses } from "../helpers";

const PREFIX = "ZeffySelectBar";

type BaseSelectBarClasses = "container" | "containerSelected" | "icon" | "clickable";

export const zeffySelectBarClasses: InternalClasses<typeof PREFIX, BaseSelectBarClasses> = {
  container: `${PREFIX}-container`,
  containerSelected: `${PREFIX}-containerSelected`,
  icon: `${PREFIX}-icon`,
  clickable: `${PREFIX}-clickable`,
};

export const StyledGrid = memo(
  styled(Grid)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffySelectBarClasses.container}`]: {
      padding: theme.spacing(2, 3),
      border: `1px solid ${theme.palette.border.neutral.quiet}`,
      borderRadius: theme.spacing(1),
      ...getTransitions(),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1.5, 3),
      },
    },

    [`&.${zeffySelectBarClasses.clickable}`]: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.surface.neutral.supershy,
        [`& .${zeffySelectBarClasses.icon}`]: {
          fill: theme.palette.text.neutral.moderate,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.surface.brand.quiet,
      },
    },

    [`&.${zeffySelectBarClasses.containerSelected}`]: {
      backgroundColor: theme.palette.surface.brand.quiet,
    },

    [`&& .${zeffySelectBarClasses.icon}`]: {
      fill: theme.palette.text.neutral.moderate,
    },
  }))
);
