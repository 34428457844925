import { memo } from "react";

import MaterialDrawer from "@mui/material/Drawer";
import { styled, Theme } from "@mui/material/styles";

import { InternalClasses } from "../helpers";

import { DrawerBaseClasses } from "./types";

const PREFIX = "ZeffyDrawer";

export const zeffyDrawerClasses: InternalClasses<typeof PREFIX, DrawerBaseClasses> = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  bottomContainer: `${PREFIX}-bottomContainer`,
  fullWidth: `${PREFIX}-fullWidth`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  content: `${PREFIX}-content`,
  contentPadding: `${PREFIX}-contentPadding`,
  header: `${PREFIX}-header`,
  headerTitle: `${PREFIX}-headerTitle`,
  headerDivider: `${PREFIX}-headerDivider`,
  mobileHeaderDivider: `${PREFIX}-mobileHeaderDivider`,
  title: `${PREFIX}-title`,
  contentMobilePadding: `${PREFIX}-contentMobilePadding`,
  contentContainer: `${PREFIX}-contentContainer`,
  stickyHeader: `${PREFIX}-stickyHeader`,
  footer: `${PREFIX}-footer`,
  stickyFooter: `${PREFIX}-stickyFooter`,
};

export const StyledDrawer = memo(
  styled(MaterialDrawer)(({ theme }: { theme: Theme }) => ({
    [`& .${zeffyDrawerClasses.container}`]: {
      height: "inherit",
      flexWrap: "nowrap",
    },

    [`& .${zeffyDrawerClasses.bottomContainer}`]: {
      borderRadius: theme.spacing(2.5, 2.5, 0, 0),
    },

    [`& .${zeffyDrawerClasses.small}`]: {
      width: 450,
    },

    [`& .${zeffyDrawerClasses.medium}`]: {
      width: 720,
    },

    [`& .${zeffyDrawerClasses.fullWidth}`]: {
      width: "100vw",
    },

    [`& .${zeffyDrawerClasses.content}`]: {
      width: "100%",
      height: "100%",
    },

    [`& .${zeffyDrawerClasses.contentPadding}`]: {
      padding: theme.spacing(3, 3),
    },

    [`& .${zeffyDrawerClasses.header}`]: {
      background: theme.palette.surface.neutral.white,
    },

    [`& .${zeffyDrawerClasses.headerTitle}`]: {
      padding: theme.spacing(2),
    },

    [`& .${zeffyDrawerClasses.headerDivider}`]: {
      padding: theme.spacing(1, 3),
    },

    [`& .${zeffyDrawerClasses.mobileHeaderDivider}`]: {
      padding: theme.spacing(0, 3),
    },

    [`& .${zeffyDrawerClasses.title}`]: {
      textAlign: "center",
    },

    [`& .${zeffyDrawerClasses.contentMobilePadding}`]: {
      padding: theme.spacing(2, 2),
    },

    [`& .${zeffyDrawerClasses.contentContainer}`]: {
      maxWidth: "100%",
      flex: 1,
    },

    [`& .${zeffyDrawerClasses.stickyHeader}`]: { position: "sticky", top: 0, zIndex: 1000, flex: 0 },

    [`& .${zeffyDrawerClasses.footer}`]: {
      flex: 0,
    },

    [`& .${zeffyDrawerClasses.stickyFooter}`]: {
      position: "sticky",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
    },
  }))
);
