import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  // Forcefully overriding the inline styles for a custom design
  dialogRoot: {
    inset: "unset !important",
    bottom: "0 !important",
    left: "0 !important",
  },
  dialogContainer: {
    alignItems: "end",
    justifyContent: "start",
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  dialogPaperFullWidth: {
    width: `calc(100% - ${theme.spacing(4)})`,
  },
  cookieContainer: {
    textAlign: "center",
  },
}));
